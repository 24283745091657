.containerSuccess {
    min-width: 200px;
    max-width: 350px;
    margin: auto;
    max-height: 330px;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 50px 30px;
    background-color: white;
    border-radius: 5px;
    text-align: center;
}

.para {
    justify-content: center;
    font-family: "Verdana", serif;
    font-size: 14px;
    font-weight: lighter;
    opacity: 0.8;
}

.head {
    font-size: 20px;
    font-weight: lighter;
}

.login {
    text-align: center;
    color: green;
    font-size: 14px;
    font-weight: lighter;
}

.dropdown {
    background-color: rgb(153, 204, 1)
}

.dropdown-menu {
    width: 140px;
    height: 60px;
    font-size: 15px;
}

.connect {
    background-color: #dae0e5;
    border-radius: 10px;
    color: green;
    text-decoration: solid;
    font-size: larger;
    font-weight: bold;
}

.bpNav {
    position: fixed;
    top: 0;
    width: 100%;
    height: 70px;
    background: white;
    left: 0;
    text-align: center;
    z-index: 1000;
}

.bpNav img {
    margin-top: 8px;
    height: 50px;
}

.bpNavBackground img {
    margin-top: 8px;
    height: 200px;
    opacity: 1;
    width: 412px;
    transform: translate3d(0px, 0px, 10px);
}

/* .backgroung_img {
    background-size: cover;
    width: 100%;
}

.welcomeMessage {
    position: absolute;
    background: white;
    margin-right: 6%;
    margin-top: -2%;
    padding: 10px;
    border-radius: 10px;
    text-align: center;
} */

.welcomHead {
    color: #007f00;
    font-weight: bold;
    font-size: 18px;
}

.connectHide {
    visibility: hidden;
}

.forgotlink {
    text-decoration: underline;
    cursor: pointer;
    color: #3c894d;
    font-weight: 700;
}

.forgotlink1 {
    text-decoration: underline;
    cursor: pointer;
    color: #3c894d;
    font-weight: 700;
}

.noaccyet {
    font-weight: 500;
}

.registerlink {
    text-decoration: underline;
    cursor: pointer;
    color: #3c894d;
    font-weight: 700;
}

.registerlink1 {
    text-decoration: underline;
    cursor: pointer;
    color: #3c894d;
    font-weight: 700;
}

.registerlink {
    position: absolute;
    right: 15px;
}

.center {
    width: 1300px;
}

.cookie-disclaimer {
    position: relative;
    z-index: 5;
    border-radius: 23px;
    /* position: fixed; */
    /* bottom: 0%; */
    width: 93%;
}

.cookie-disclaimer .close {
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    padding: 10px;
    opacity: 1;
}

.cookie-disclaimer .cookie-disclaimer-title {
    color: white;
    margin-bottom: 0.5rem;
}

.cookie-disclaimer .cookie-disclaimer-text {
    display: block;
    text-align: center;
}

.cookie-disclaimer .cookie-disclaimer-text p {
    color: white;
}

.cookie-disclaimer .cookie-disclaimer-text p:last-of-type {
    margin-bottom: 0;
}

.cookie-disclaimer .cookie-disclaimer-text a {
    font-weight: 700;
    color: #fff;
    text-decoration: underline;
    cursor: pointer;
}

.imgLogoWidth {
    width: 90%;
}

.iframeMistURL {
    display: none;
}

@media (min-width: 500px) {
    .successbody {
        background-image: linear-gradient(to right, rgb(0, 120, 51), rgb(153, 204, 1));
        width: 100%;
        height: 100%;
    }
}

@media (min-width: 280px) and (max-width: 319px) {
    .backgroung_img {
        background-image: linear-gradient(to right, rgb(0, 120, 51), rgb(153, 204, 1));
        width: 110%;
        height: 190px;
        display: block;
        margin-left: -12px;
        /* margin-right: auto; */
    }

    .welcomeMessage {
        position: relative;
        background: white;
        margin-left: -3%;
        /* margin-right: auto; */
        margin-top: -6%;
        padding: 10px;
        border-radius: 10px;
        text-align: center;
        width: 104%;
    }

    .Noacc {
        width: 80px;
        height: 40px;
    }

    .registerhere {
        width: 80px;
        height: 40px;
    }

    .center {
        width: 230px;
    }

    .btnCnt {
        /* width: 50%; */
        height: 33px;
        /* margin-right: 25px; */
        margin-top: 5px;
    }
}

@media (min-width: 320px) and (max-width: 359px) {
    .backgroung_img {
        background-image: linear-gradient(to right, rgb(0, 120, 51), rgb(153, 204, 1));
        width: 102%;
        height: 200px;
        display: block;
        margin-left: -4px;
        /* margin-right: auto; */
    }

    .welcomeMessage {
        position: relative;
        background: white;
        margin-left: 1%;
        /* margin-right: auto; */
        margin-top: -8%;
        padding: 10px;
        border-radius: 10px;
        text-align: center;
        width: 96%;
    }

    .Noacc {
        width: 80px;
        height: 40px;
    }

    .registerhere {
        width: 80px;
        height: 40px;
    }

    .center {
        width: auto;
    }

    .btnCnt {
        /* width: 49%; */
        height: 34px;
        /* margin-right: 25px; */
        margin-top: 5px;
    }
}

@media (min-width: 360px) and (max-width: 374px) {
    .backgroung_img {
        background-image: linear-gradient(to right, rgb(0, 120, 51), rgb(153, 204, 1));
        width: 102%;
        height: 230px;
        display: block;
        margin-left: -1%;
        /* margin-right: auto; */
    }

    .welcomeMessage {
        position: relative;
        background: white;
        margin-left: auto;
        margin-right: 9%;
        margin-top: -11%;
        padding: 10px;
        border-radius: 10px;
        text-align: center;
        width: 99%;
    }

    .center {
        width: auto;
    }

    .btnCnt {
        /* width: 44%; */
        margin-top: 8px;
    }
}

@media (min-width: 375px) and (max-width: 410px) {
    .backgroung_img {
        background-image: linear-gradient(to right, rgb(0, 120, 51), rgb(153, 204, 1));
        width: 101%;
        height: 233px;
        display: block;
        margin-left: -2px;
        /* margin-right: auto; */
    }

    .welcomeMessage {
        position: relative;
        background: white;
        margin-left: 2%;
        /* margin-right: auto; */
        margin-top: -7%;
        padding: 10px;
        border-radius: 10px;
        text-align: center;
        width: 95%;
    }

    .center {
        width: auto;
    }

    .btnCnt {
        /* width: 45%; */
        height: 38px;
        /* margin-right: 25px; */
        margin-top: 5px;
    }
}

@media (min-width: 411px) and (max-width: 539px) {
    .backgroung_img {
        background-image: linear-gradient(to right, rgb(0, 120, 51), rgb(153, 204, 1));
        width: 101%;
        height: 255px;
        display: block;
        margin-left: -2px;
        /* margin-right: auto; */
    }

    .welcomeMessage {
        position: relative;
        background: white;
        margin-left: 2%;
        /* margin-right: auto; */
        margin-top: -7%;
        padding: 10px;
        border-radius: 10px;
        text-align: center;
        width: 96%;
    }

    .center {
        width: auto;
    }

    .btnCnt {
        /* width: 45%; */
        height: 36px;
        margin-top: 7px;
    }
}

@media (min-width: 540px) and (max-width: 767px) {
    .backgroung_img {
        background-image: linear-gradient(to right, rgb(0, 120, 51), rgb(153, 204, 1));
        width: 99%;
        height: 300px;
        display: block;
        margin-left: 2px;
        /* margin-right: auto; */
    }

    .welcomeMessage {
        position: relative;
        background: white;
        margin-left: 2%;
        /* margin-right: auto; */
        margin-top: -7%;
        padding: 10px;
        border-radius: 10px;
        text-align: center;
        width: 95%;
    }

    .center {
        width: auto;
    }

    .btnCnt {
        /* width: 30%; */
        height: 35px;
        margin-top: 5px;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .backgroung_img {
        background-image: linear-gradient(to right, rgb(0, 120, 51), rgb(153, 204, 1));
        width: 65%;
        height: 300px;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .welcomeMessage {
        position: relative;
        background: white;
        margin-left: auto;
        margin-right: auto;
        margin-top: -5%;
        padding: 10px;
        border-radius: 10px;
        text-align: center;
        width: 62%;
    }

    .center {
        width: auto;
    }

    .btnCnt {
        /* width: 25%; */
        height: 35px;
        /* margin-right: 25px; */
        margin-top: 5px;
    }
}

@media (min-width: 1024px) and (max-width: 1365px) {
    .backgroung_img {
        background-image: linear-gradient(to right, rgb(0, 120, 51), rgb(153, 204, 1));
        width: 65%;
        height: 320px;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .welcomeMessage {
        position: relative;
        background: white;
        margin-left: auto;
        margin-right: auto;
        margin-top: -4%;
        padding: 10px;
        border-radius: 10px;
        text-align: center;
        width: 62%;
    }

    .center {
        width: auto;
    }

    .btnCnt {
        /* width: 19%; */
        height: 35px;
        /* margin-right: 25px; */
        margin-top: 5px;
    }
}

@media (min-width: 1366px) and (max-width: 1500px) {
    .backgroung_img {
        background-image: linear-gradient(to right, rgb(0, 120, 51), rgb(153, 204, 1));
        width: 50%;
        height: 315px;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .welcomeMessage {
        position: relative;
        background: white;
        margin-left: auto;
        margin-right: auto;
        margin-top: -2%;
        padding: 10px;
        border-radius: 10px;
        text-align: center;
        width: 48%;
    }

    .center {
        width: auto;
    }

    .btnCnt {
        width: 20%;
        height: 36px;
        margin-top: 5px;
    }
}


@media (min-width: 1501px) and (max-width: 1600px) {
    .backgroung_img {
        background-image: linear-gradient(to right, rgb(0, 120, 51), rgb(153, 204, 1));
        width: 50%;
        height: 315px;
        display: block;
        margin-left: 25%;

    }

    .welcomeMessage {
        position: relative;
        background: white;
        margin-left: 26%;

        margin-top: -2%;
        padding: 10px;
        border-radius: 10px;
        text-align: center;
        width: 48%;
    }

    .center {
        width: auto;
    }

    .btnCnt {
        /* width: 20%; */
        height: 36px;
        margin-top: 5px;
    }
}

@media (min-width: 1601px) and (max-width: 1700px) {
    .backgroung_img {
        background-image: linear-gradient(to right, rgb(0, 120, 51), rgb(153, 204, 1));
        width: 50%;
        height: 330px;
        display: block;
        margin-left: 25%;
    }

    .welcomeMessage {
        position: relative;
        background: white;
        margin-left: 26%;

        margin-top: -2%;
        padding: 10px;
        border-radius: 10px;
        text-align: center;
        width: 48%;
    }

    .center {
        width: auto;
    }

    .btnCnt {
        /* width: 20%; */
        height: 36px;
        margin-top: 5px;
    }
}

@media (min-width: 1701px) and (max-width: 1800px) {
    .backgroung_img {
        background-image: linear-gradient(to right, rgb(0, 120, 51), rgb(153, 204, 1));
        width: 50%;
        height: 410px;
        display: block;
        margin-left: 24%;
    }

    .welcomeMessage {
        position: relative;
        background: white;
        margin-left: 25%;
        margin-top: -2%;
        padding: 10px;
        border-radius: 10px;
        text-align: center;
        width: 48%;
    }

    .center {
        width: auto;
    }

    .btnCnt {
        /* width: 20%; */
        height: 36px;
        margin-top: 5px;
    }
}

@media (min-width: 1801px) and (max-width: 1900px) {
    .backgroung_img {
        background-image: linear-gradient(to right, rgb(0, 120, 51), rgb(153, 204, 1));
        width: 50%;
        height: 410px;
        display: block;
        margin-left: 23%;
    }

    .welcomeMessage {
        position: relative;
        background: white;
        margin-left: 24%;
        margin-right: auto;
        margin-top: -2%;
        padding: 10px;
        border-radius: 10px;
        text-align: center;
        width: 48%;
    }

    .center {
        width: auto;
    }

    .btnCnt {
        /* width: 20%; */
        height: 36px;
        margin-top: 5px;
    }
}

@media (min-width: 1901px) and (max-width: 2000px) {
    .backgroung_img {
        background-image: linear-gradient(to right, rgb(0, 120, 51), rgb(153, 204, 1));
        width: 50%;
        height: 410px;
        display: block;
        margin-left: 24%
    }

    .welcomeMessage {
        position: relative;
        background: white;
        margin-left: 25%;
        margin-right: auto;
        margin-top: -2%;
        padding: 10px;
        border-radius: 10px;
        text-align: center;
        width: 48%;
    }

    .center {
        width: auto;
    }

    .btnCnt {
        /* width: 20%; */
        height: 36px;
        margin-top: 5px;
    }
}

@media (min-width: 2001px) and (max-width: 2100px) {
    .backgroung_img {
        background-image: linear-gradient(to right, rgb(0, 120, 51), rgb(153, 204, 1));
        width: 50%;
        height: 410px;
        display: block;
        margin-left: 24%;
    }

    .welcomeMessage {
        position: relative;
        background: white;
        margin-left: 25%;
        margin-right: auto;
        margin-top: -2%;
        padding: 10px;
        border-radius: 10px;
        text-align: center;
        width: 48%;
    }

    .center {
        width: auto;
    }

    .btnCnt {
        /* width: 20%; */
        height: 36px;
        margin-top: 5px;
    }
}

@media (min-width: 2101px) and (max-width: 2200px) {
    .backgroung_img {
        background-image: linear-gradient(to right, rgb(0, 120, 51), rgb(153, 204, 1));
        width: 50%;
        height: 450px;
        display: block;
        margin-left: 24%;
    }

    .welcomeMessage {
        position: relative;
        background: white;
        margin-left: 25%;
        margin-right: auto;
        margin-top: -2%;
        padding: 10px;
        border-radius: 10px;
        text-align: center;
        width: 48%;
    }

    .center {
        width: auto;
    }

    .btnCnt {
        /* width: 20%; */
        height: 36px;
        margin-top: 5px;
    }
}

@media (min-width: 2201px) and (max-width: 2300px) {
    .backgroung_img {
        background-image: linear-gradient(to right, rgb(0, 120, 51), rgb(153, 204, 1));
        width: 50%;
        height: 475px;
        display: block;
        margin-left: 23%;
    }

    .welcomeMessage {
        position: relative;
        background: white;
        margin-left: 24%;
        margin-right: auto;
        margin-top: -2%;
        padding: 10px;
        border-radius: 10px;
        text-align: center;
        width: 48%;
    }

    .center {
        width: auto;
    }

    .btnCnt {
        width: 20%;
        height: 36px;
        margin-top: 5px;
    }
}

@media (min-width: 2301px) and (max-width: 2600px) {
    .backgroung_img {
        background-image: linear-gradient(to right, rgb(0, 120, 51), rgb(153, 204, 1));
        width: 65%;
        height: 440px;
        display: block;
        margin-left: 54%;
    }

    .welcomeMessage {
        position: relative;
        background: white;
        margin-left: 55%;
        margin-right: auto;
        margin-top: -2%;
        padding: 10px;
        border-radius: 10px;
        text-align: center;
        width: 63%;
    }

    .center {
        width: auto;
    }

    .btnCnt {
        /* width: 20%; */
    }
}

@media (min-width: 2601px) and (max-width: 4000px) {
    .backgroung_img {
        background-image: linear-gradient(to right, rgb(0, 120, 51), rgb(153, 204, 1));
        width: 50%;
        height: 460px;
        display: block;
        margin-left: 77%;
    }

    .welcomeMessage {
        position: relative;
        background: white;
        margin-left: 78%;
        margin-right: auto;
        padding: 10px;
        border-radius: 10px;
        text-align: center;
        width: 48%;
        margin-top: -2%;
    }

    .center {
        width: auto;
    }

    .btnCnt {
        /* width: 20%; */
    }
}

@media (min-width: 4001px) and (max-width: 7000px) {
    .backgroung_img {
        background-image: linear-gradient(to right, rgb(0, 120, 51), rgb(153, 204, 1));
        width: 65%;
        height: 440px;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .welcomeMessage {
        position: relative;
        background: white;
        margin-left: auto;
        margin-right: auto;
        padding: 10px;
        border-radius: 10px;
        text-align: center;
        width: 63%;
        margin-top: -3%;
    }

    .center {
        width: auto;
    }

    .btnCnt {
        /* width: 20%; */
    }
}