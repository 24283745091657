@media (min-width: 500px) {
    body {

        width: 100%;
        height: 100%;
        padding: 25px;
        display: flex;
        align-items: center;
        margin-top: auto;
        margin-bottom: auto;
        /* background: url('/splashpages/static/media/Fourcourt.3f40315e.jpg') no-repeat center center fixed;  */
        /* background: url('./Fourcourt.jpg') no-repeat center center fixed;  */
        -webkit-background-size: cover;
        -moz-background-size: cover;
        /* background-image: "/static/media/Fourcourt.3f40315e.jpg"; */
        -o-background-size: cover;
        background-size: cover;
        /* font-family: "Helvetica Neue",Helvetica,Arial,sans-serif; */
        line-height: 1.42857143;
        color: #333;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
        font-size: 14px !important;
    }
}

@media (max-width: 500px) {

    body {
        background-image: linear-gradient(to right, rgb(0, 120, 51), rgb(153, 204, 1));
        width: 100%;
        height: 100%;
        padding: 25px;
        display: flex;
        align-items: center;
        margin-top: auto;
        margin-bottom: auto;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
        font-size: 14px !important;

    }
}


.form-container {
    min-width: 200px;
    max-width: 350px;
    margin: auto;
    max-height: 650px;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 50px 25px 50px 25px;
    background-color: white;
    border-radius: 5px;
    /* font-family: "sans-serif" !important; */
    font-size: 14px;
    line-height: 1.42857143 !important;
    color: #333 !important;
}


.divTitle {
    font-weight: bolder;
    text-align: center;
}

.BPLogo {

    width: 120px;
    margin: auto;
    height: 186px;
    /* padding-top: 10px; */
}

.imgLogo {

    width: 120px;
}

.customtext {
    padding: 6px !important;
    font-size: 18px !important;
}

.btnCustom {
    background-color: green !important;
    color: white !important;
    padding-bottom: 10px;
    padding-top: 10px;
    margin-bottom: 5px;
    font-size: 14px !important;
}

.linkColor {
    color: green !important;
}

.setlnk {

    padding-left: 16%
}

.setAccount {

    text-align: center;
}

.errorMessage {
    color: red;
}

.form-control::-webkit-input-placeholder {
    color: darkgrey;
    font-weight: 100;
}